@font-face {
  font-family: 'smidyo-iconfont';
  src:  url('fonts/smidyo-iconfont.eot?odam9s');
  src:  url('fonts/smidyo-iconfont.eot?odam9s#iefix') format('embedded-opentype'),
    url('fonts/smidyo-iconfont.ttf?odam9s') format('truetype'),
    url('fonts/smidyo-iconfont.woff?odam9s') format('woff'),
    url('fonts/smidyo-iconfont.svg?odam9s#smidyo-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'smidyo-iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-company:before {
  content: "\e900";
}
.icon-account-group:before {
  content: "\e901";
}
.icon-account-offline:before {
  content: "\e902";
}
.icon-account-user:before {
  content: "\e903";
}
.icon-add:before {
  content: "\e904";
}
.icon-address:before {
  content: "\e905";
}
.icon-aggregator:before {
  content: "\e906";
}
.icon-arrow-down:before {
  content: "\e907";
}
.icon-arrow-left:before {
  content: "\e908";
}
.icon-arrow-right:before {
  content: "\e909";
}
.icon-arrow-up:before {
  content: "\e90a";
}
.icon-block-assert:before {
  content: "\e90b";
}
.icon-block-effect:before {
  content: "\e90c";
}
.icon-block-element:before {
  content: "\e90d";
}
.icon-block-operation:before {
  content: "\e90e";
}
.icon-block-source:before {
  content: "\e90f";
}
.icon-cancel:before {
  content: "\e910";
}
.icon-chat:before {
  content: "\e911";
}
.icon-checkout:before {
  content: "\e912";
}
.icon-code:before {
  content: "\e913";
}
.icon-company:before {
  content: "\e914";
}
.icon-config:before {
  content: "\e915";
}
.icon-constant:before {
  content: "\e916";
}
.icon-dashboard:before {
  content: "\e917";
}
.icon-delete:before {
  content: "\e918";
}
.icon-details:before {
  content: "\e919";
}
.icon-documentation:before {
  content: "\e91a";
}
.icon-download:before {
  content: "\e91b";
}
.icon-edit:before {
  content: "\e91c";
}
.icon-file:before {
  content: "\e91d";
}
.icon-home:before {
  content: "\e91e";
}
.icon-logout:before {
  content: "\e91f";
}
.icon-new:before {
  content: "\e920";
}
.icon-offering:before {
  content: "\e921";
}
.icon-ok:before {
  content: "\e922";
}
.icon-order:before {
  content: "\e923";
}
.icon-page:before {
  content: "\e924";
}
.icon-pipeline-form:before {
  content: "\e925";
}
.icon-pipeline-input:before {
  content: "\e926";
}
.icon-pipeline-output:before {
  content: "\e927";
}
.icon-pipeline-process:before {
  content: "\e928";
}
.icon-pipeline-yield:before {
  content: "\e929";
}
.icon-pipeline:before {
  content: "\e92a";
}
.icon-quote:before {
  content: "\e92b";
}
.icon-receipt:before {
  content: "\e92c";
}
.icon-remove:before {
  content: "\e92d";
}
.icon-row:before {
  content: "\e92e";
}
.icon-sales-domain:before {
  content: "\e92f";
}
.icon-save:before {
  content: "\e930";
}
.icon-smidyo:before {
  content: "\e931";
}
.icon-table:before {
  content: "\e932";
}
.icon-test:before {
  content: "\e933";
}
.icon-triangle-down:before {
  content: "\e934";
}
.icon-triangle-left:before {
  content: "\e935";
}
.icon-triangle-right:before {
  content: "\e936";
}
.icon-triangle-up:before {
  content: "\e937";
}
.icon-upload:before {
  content: "\e938";
}
.icon-user:before {
  content: "\e939";
}
.icon-vendor:before {
  content: "\e93a";
}
.icon-visual:before {
  content: "\e93b";
}
.icon-warning:before {
  content: "\e93c";
}
